/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment,
  useState,
  useEffect
} from 'react';

import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import AuthUserRegisterGuard from 'src/components/AuthRouteRegister';
import AuthUserRegisterOrMiCabalGuard from 'src/components/AuthRouteRegisterOrFromMiCabal';
import GuestRoute from 'src/components/GuestRoute';
import AuthRouteTCRegisterDIMO from './components/AuthRouteTCRegisterDIMO';

const routesConfig = [
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/views/main'))
      },
      {
        exact: true,
        path: '/app/prepaidcard',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/movimientos',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/Movimientos'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/solicitudImpresion',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/SolicitudImpresion'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/solicitudPrepaga',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/SolicitudPrepaga'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/activacionPlastico',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/ActivacionPlastico'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/cambiarPin',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/CambiarPin'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/configuraciones',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/Configuraciones'))
      },
      {
        exact: true,
        path: '/app/prepaidcard/facturas',
        component: lazy(() => import('src/views/pages/PrepaidCardDetail/FacturasDimo'))
      },
      {
        exact: true,
        path: '/app/creditcard',
        //guard: AuthUserRegisterOrMiCabalGuard,
        guard: AuthRouteTCRegisterDIMO,
        component: lazy(() => import('src/views/pages/CreditCardDetail'))
      },
      {
        exact: true,
        path: '/app/creditcard/movimientos',
        guard: AuthUserRegisterOrMiCabalGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/Movimientos'))
      },
      {
        exact: true,
        path: '/app/creditcard/extractos',
        guard: AuthUserRegisterOrMiCabalGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/Extractos'))
      },
      {
        exact: true,
        path: '/app/creditcard/configuraciones',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/Configuraciones'))
      },
      {
        exact: true,
        path: '/app/creditcard/cambiarPin',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/CambiarPin'))
      },
      {
        exact: true,
        path: '/app/verificar/identidad',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/verificarIdentidad/index'))
      },
      {
        exact: true,
        path: '/app/verificar/identidad/exitoso',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/verificarIdentidad/index'))
      },
      {
        exact: true,
        path: '/app/verificar/identidad/fallido',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/verificarIdentidad/index'))
      },
      {
        exact: true,
        path: '/app/creditcard/vincularDimoTC',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/VincularTCDimo'))
      },
      {
        exact: true,
        path: '/app/creditcard/vincularDimoTC/exitoso',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/VincularTCDimo'))
      },
      {
        exact: true,
        path: '/app/creditcard/vincularDimoTC/fallido',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/CreditCardDetail/VincularTCDimo'))
      },
      {
        exact: true,
        path: '/app/privacidad',
        component: lazy(() => import('src/constants/Terminos'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/seguridad',
        component: lazy(() => import('src/views/pages/seguridad'))
      },
      {
        exact: true,
        path: '/app/account/modificar/datos',
        component: lazy(() => import('src/views/pages/AccountView/UpdateUserProfileView'))
      },
      {
        exact: true,
        path: '/app/account/modificar/password',
        component: lazy(() => import('src/views/pages/AccountView/ChangePasswordView'))
      },
      {
        exact: true,
        path: '/app/account/modificar/email',
        component: lazy(() => import('src/views/pages/AccountView/ChangeEmailRequestView'))
      },
      {
        exact: true,
        path: '/app/account/modificar/email/confirmacion',
        component: lazy(() => import('src/views/pages/AccountView/ChangeEmailConfirmView'))
      },
      {
        exact: true,
        path: '/app/account/modificar/celular',
        component: lazy(() => import('src/views/pages/AccountView/ChangePhoneNumberRequestView'))
      },
      {
        exact: true,
        path: '/app/account/modificar/celular/confirmacion',
        component: lazy(() => import('src/views/pages/AccountView/ChangePhoneNumberConfirmView'))
      },
      {
        exact: true,
        path: '/app/payment',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/PaymentListView'))
      },
      {
        exact: true,
        path: '/app/payment/ticket/compra',
        // guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/GenerateTicket'))
      },
      {
        exact: true,
        path: '/app/payment/ticket/listar',
        // guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/ListTicket'))
      },
      {
        exact: true,
        path: '/app/payment/detail',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/PaymentDetailView'))
      },
      {
        exact: true,
        path: '/app/payment/confirmacion',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/ConfirmationPayment'))
      },
      {
        exact: true,
        path: '/app/payment/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransactionVoucher'))
      },
      {
        exact: true,
        path: '/app/payment/redpagos/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/PaymentResult/PaymentVoucherView'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/tarjetaPago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/importePago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/cuentaPago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/confirmacionPago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/otraTC',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment/PaymentExtract/PaymentCreditCardManual'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/pagar',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment/PaymentExtract/PaymentExtractDetail'))
      },
      //Nuevo
      {
        exact: true,
        path: '/app/payment/creditCard/confirmacion',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment/PaymentExtract/ConfirmationPayment'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment/PaymentExtract/PaymentResult'))
      },
      {
        exact: true,
        path: '/app/payment/creditCard/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CreditCardPayment/PaymentExtract/PaymentVoucher'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/socioTercero',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment/AccountToPaySelector/OtrasCuentas/CuentaCoopTerceroSlcSocio'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/cuentaTercero',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment/AccountToPaySelector/OtrasCuentas/CuentaCoopTerceroSlcCta'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/importePago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/cuentaPago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/confirmacionPago',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment/PaymentResult'))
      },
      {
        exact: true,
        path: '/app/payment/cooperativa/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/CoopAccountPayment/PaymentVoucher'))
      },
      {
        exact: true,
        path: '/app/transferencias',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/DimoTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/nuevo',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/DimoTransfer/NewDimoAccountForm'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/miscuentas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/DimoTransfer/MyDimoAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/otrascuentas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/DimoTransfer/OtherDimoAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/bancos',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/BankTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/bancos/nuevo',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/BankTransfer/BankTranferNewAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/bancos/cuenta',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/BankTransfer/BankTransferAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/cooperativas',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/cooperativas/nuevo',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer/NewCoopSelection'))
      },
      {
        exact: true,
        path: '/app/transferencias/cooperativas/miscuentas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer/MyCoopAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/cooperativas/otrascuentas',
        guard: AuthUserRegisterGuard,
        //component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer/OtherCoopAccount'))
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer/NewCoopAccountForm'))
      },
      {
        exact: true,
        path: '/app/transferencias/cooperativas/otrascuentas/formulario',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/CoopTransfer/OtherCoopAccount/CoopAccount'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/origen',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/OriginTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/confirmacion',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/ConfirmationTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/dimo/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransferResult'))
      },
      {
        exact: true,
        path: '/app/transferencias/otrasbilleteras',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/DestinationTransfer/OtherWalletTransfer'))
      },
      {
        exact: true,
        path: '/app/transferencias/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransactionVoucher'))
      },
      {
        exact: true,
        path: '/app/transaccion/confirmacion',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/ConfirmationTransfer'))
      },
      {
        exact: true,
        path: '/app/transaccion/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransferResult'))
      },
      {
        exact: true,
        path: '/app/transaccion/comprobante',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransactionVoucher'))
      },
      {
        exact: true,
        path: '/app/compras/qr/confirmacion',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/ConfirmationTransfer'))
      },
      // {
      //   exact: true,
      //   path: '/app/compras/qr/confirmacion',
      //   guard: AuthUserRegisterGuard,
      //   component: lazy(() => import('src/views/pages/Transfer/ConfirmationTransfer'))
      // },
      {
        exact: true,
        path: '/app/compras/qr/atm/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/ConfirmationQrAtm'))
      },
      {
        exact: true,
        path: '/app/compras/qr/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Transfer/TransferResult'))
      },
      {
        exact: true,
        path: '/app/payment/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Payment/ServicePayment/PaymentResult'))
      },
      {
        exact: true,
        path: '/app/completar/registro',
        component: lazy(() => import('src/views/pages/CompleteRegisterUploadFile'))
      },
      {
        exact: true,
        path: '/app/mensajes/registro/completar/debecompletar',
        component: lazy(() => import('src/views/pages/CompleteRegister/CompleteRegisterToAccessView'))
      },
      {
        exact: true,
        path: '/app/cooperativas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/SelectCoopUsuario'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/ahorros/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaAhorroDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/dpf/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaAhorroDPFDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/ruedasahorro/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaRuedaAhoDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/prestamos/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaPrestamoDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/tarjetas/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaTarjetaDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/excedentes/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaExcedente'))
      },
      {
        exact: true,
        path: '/app/cooperativas/cuentas/obligaciones/detalle',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/CoopParticipante/CoopCuentaDetalle/CoopCuentaObligacionDet'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCooperativa'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/preguntas',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCooperativa'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/exito',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCooperativa'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/fallido',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCooperativa/AgregarCooperativaError'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/cuentaAhorro',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCuentaAhorro'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/cuentaAhorro/ingresarDatos',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCuentaAhorro'))
      }, {
        exact: true,
        path: '/app/cooperativas/agregar/cuentaAhorro/exito',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCuentaAhorro'))
      },
      {
        exact: true,
        path: '/app/cooperativas/agregar/cuentaAhorro/fallido',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/FinancialAccounts/CoopAccounts/AgregarCuentaAhorro/AgregarCuentaAhorroError'))
      },
      {
        exact: true,
        path: '/app/demo/qr/generacion',
        component: lazy(() => import('src/views/pages/Demo/QR/Generacion'))
      },

      {
        exact: true,
        path: '/app/demo/qr/resultado',
        component: lazy(() => import('src/views/pages/Demo/QR/Resultado'))
      },
      {
        exact: true,
        path: '/app/gestion-efectivo',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/GestionEfectivo'))
      },
      {
        exact: true,
        path: '/app/gestion-efectivo/aqui-pago',
        //guard: AuthUserRegisterGuard,
        //component: lazy(() => import('src/views/pages/QR/ReadQR/OriginTransfer'))
        component: lazy(() => import('src/views/pages/GestionEfectivo/AquiPago'))
      },
      {
        exact: true,
        path: '/app/qr',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR'))
      },
      {
        exact: true,
        path: '/app/qr/mi-qr',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/MiQr'))
      },
      {
        exact: true,
        path: '/app/generar/qr',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/GnerateQR'))
      },
      {
        exact: true,
        path: '/app/generar/deposito-extraccion',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/GestionEfectivo/DepositExtraction/'))
      },
      {
        exact: true,
        path: '/app/leer/qr',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/ReadQR'))
      },
      {
        exact: true,
        path: '/app/leer/qr/origen',
        //guard: AuthUserRegisterGuard,
        //component: lazy(() => import('src/views/pages/QR/ReadQR/OriginTransfer'))
        component: lazy(() => import('src/views/pages/Transfer/OriginTransfer'))
      },
      {
        exact: true,
        path: '/app/leer/qr/confirmacion',
        guard: AuthUserRegisterGuard,
        //component: lazy(() => import('src/views/pages/QR/ConfirmationTransfer'))
        component: lazy(() => import('src/views/pages/Transfer/ConfirmationTransfer'))
      },
      {
        exact: true,
        path: '/app/leer/qr/resultado',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/ReadQR/TransferResult'))
      },
      {
        exact: true,
        path: '/app/promociones/qr',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/QR/Promociones/PromocionQRView'))
      },
      {
        exact: true,
        path: '/app/exchange',
        // guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Exchange/index'))
      },
      {
        exact: true,
        path: '/app/banner',
        // guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/BannerInfo/index'))
      },

      {
        exact: true,
        path: '/app/tarifas',
        //guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/Tariff'))
      },
      {
        exact: true,
        path: '/app/recargasaldo',
        guard: AuthUserRegisterGuard,
        component: lazy(() => import('src/views/pages/MinutesRecharge'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {
        ///*
        <Redirect
          exact
          from="/"
          to="/home"
        />
        //*/
      }

      <Route
        exact
        path="/"
        component={lazy(() => import('src/views/pages/HomeView'))}
      />

      <Route
        exact
        path="/preguntas"
        component={lazy(() => import('src/views/pages/HomeView/FAQView'))}
      />
      <Route
        exact
        path="/masDimo"
        component={lazy(() => import('src/views/pages/HomeView/MasDimo'))}
      />
      <Route
        exact
        path="/404"
        component={lazy(() => import('src/views/pages/Error404View'))}
      />
      <GuestRoute
        exact
        path="/login"
        component={lazy(() => import('src/views/auth/LoginView'))}
      />
      <GuestRoute
        exact
        path="/register"
        //component={lazy(() => import('src/views/auth/RegisterView'))}
        component={lazy(() => import('src/views/auth/RegisterViewNewUX'))}
      />

      <Route
        exact
        path="/verifyEmail"
        component={lazy(() => import('src/views/auth/VerifyEmailView'))}
      />

      <GuestRoute
        exact
        path="/forgotPassword"
        component={lazy(() => import('src/views/auth/ForgotPasswordView'))}
      />

      <GuestRoute
        exact
        path="/restorePassword/:tokenRestorePassword"
        component={lazy(() => import('src/views/auth/RestorePasswordView'))}
      />

      <Route
        exact
        path="/privacidad"
        component={lazy(() => import('src/constants/Terminos.js'))}
      />

      {
        routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard route={route.path}>
                  <Layout>
                    {route.routes
                      ? renderRoutes(route.routes)
                      : <Component {...props} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })
      }

      <Route
        path="*"
        render={(props) => (
          <Route
            exact
            path="/home"
            component={HomeView}
          />
          /*
          <MainLayout {...props}>

            <Switch>
              <Route
                exact
                path="/home"
                component={HomeView}
              />
              <Redirect to="/404" />
            </Switch>
        </MainLayout>*/
        )}
      />
      <Redirect to="/404" />
    </Switch>
  </Suspense>
) : null);

function Routes() {
  const [routes, setRoutes] = useState(null)

  useEffect(() => {
    const loadRoutes = () => {
      setRoutes(renderRoutes(routesConfig));
    }
    loadRoutes()
    // eslint-disable-next-line
  }, []);

  return routes ? routes : <LoadingScreen />;
}

export default Routes;
