import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';

function AuthGuard({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  // Rutas que solo estan disponibles en la app movil
  const routeOnlyMobile = ['/app/completar/registro'];
  // Ruta actual
  const route = window.location.pathname;

  // Funcion que determina si la aplicacion esta corriendo en un dispositivo movil
  // si encuentra la variable window.ReactNativeWebView es porque esta corriendo en un dispositivo movil
  const isMobile = () => {
    // console.log('isMobile -->',(window.ReactNativeWebView ? true : false))
    return (window.ReactNativeWebView ? true : false);
  }

  // Funcion que determina si la ruta actual es solo para movil
  // si la ruta actual se encuentra en el arreglo routeOnlyMobile, es porque es solo para movil
  const isRouteOnlyMobile = () => {
      // console.log('isRouteOnlyMobile -->',routeOnlyMobile.includes(route))
    return routeOnlyMobile.includes(route);
  }

  // Si el usuario no esta logueado, se redirecciona a la pagina de login
  if (!account.user) {
    return <Redirect to="/login" />;
  }

  // Si la ruta actual es solo para movil y no se encuentra en un dispositivo movil
  if(isRouteOnlyMobile() && !isMobile()){
    enqueueSnackbar('Por su seguridad, esta sección solo está disponible en la app.', {
      variant: 'info'
    });
    return <Redirect to="/app" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
